// serviceWorkerRegistration.js

export function register() {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register(`${process.env.PUBLIC_URL}/service-worker.js`)  // Referencing the service worker from the public folder
          .then((registration) => {
            console.log('Service Worker registered:', registration);
          })
          .catch((error) => {
            console.log('Service Worker registration failed:', error);
          });
      });
    } else {
      console.log('Service Worker is not supported in this browser.');
    }
  }
  
  // Function to unregister the service worker (for cleanup)
  export function unregister() {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .getRegistrations()
          .then((registrations) => {
            registrations.forEach((registration) => {
              registration.unregister();
            });
          })
          .catch((error) => {
            console.error('Service Worker unregistration failed:', error);
          });
      });
    }
  }
  