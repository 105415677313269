import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getMessaging } from "firebase/messaging"; // Import messaging

const firebaseConfig = {
  apiKey: "AIzaSyBHIl4DX8fPV9h8owKO10fXV8diqf374Ts",
  authDomain: "suiitlibrary.firebaseapp.com",
  databaseURL: "https://suiitlibrary-default-rtdb.firebaseio.com",
  projectId: "suiitlibrary",
  storageBucket: "suiitlibrary.appspot.com",
  messagingSenderId: "70751869896",
  appId: "1:70751869896:web:fab20521b5496e95aafefd",
  measurementId: "G-FX6G3Y7KYQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);
const messaging = getMessaging(app); // Initialize messaging

export { database, storage, auth, provider, messaging }; // Export messaging
