import { openDB } from 'idb';
const DB_NAME = 'LibraryDB';
const DB_VERSION = 2; // Increment this version when updating the schema
const BOOKS_STORE = 'books';
const ISSUES_STORE = 'issues';

// Function to open or create the IndexedDB
const initDB = async () => {
  return await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(BOOKS_STORE)) {
        db.createObjectStore(BOOKS_STORE, { keyPath: 'id' });
      }
      if (!db.objectStoreNames.contains(ISSUES_STORE)) {
        db.createObjectStore(ISSUES_STORE, { keyPath: 'id' });
      }
    },
  });
};

// Function to save data to IndexedDB
export const saveDataToDB = async (storeName, data) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.clear(); // Clear previous entries before storing new ones

  for (const item of data) {
    await store.put(item);
  }

  await tx.done; // Ensure transaction is completed
};

// Function to fetch data from IndexedDB
export const getDataFromDB = async (storeName) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const allData = await store.getAll();
  await tx.done; // Ensure transaction is completed
  return allData;
};

// Optional: Function to delete a specific item from IndexedDB
export const deleteDataFromDB = async (storeName, id) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.delete(id);
  await tx.done; // Ensure transaction is completed
};

// Optional: Function to clear a specific store
export const clearStore = async (storeName) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.clear();
  await tx.done; // Ensure transaction is completed
};
