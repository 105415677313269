import Sambalpuri from './images/Sambalpuri.png';
import Sambalpuri1 from './images/Sambalpuri1.png';
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import SUIITLOGO from './images/SUIITLOGO.png'

const Footer = () => {


  return (
    <div>
      
    <footer className="bg-light py-5">
      {/* Floating Chat Button */}
      <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1050 }}>
             </div>

      <div className="container">
        {/* Google Map Section */}
        <div className="row mb-5">
          <div className="col-12">
            <div className="map-container" style={{ marginTop: '-30px', backgroundColor: 'transparent' }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d152987.89685159255!2d83.88058624586034!3d21.473493652851896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a213c368309af57%3A0xdcd4d19c95624841!2sSambalpur%20University%20Institute%20of%20Information%20Technology%20(SUIIT)%2C%20Burla!5e0!3m2!1sen!2sus!4v1730644142323!5m2!1sen!2sus"
                width="100%"
                height="350"
                style={{ border: 0, borderRadius: '8px' }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Sambalpur University Location"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Footer Content Sections */}
        <div className="row">
          {/* Logo and Institution Information */}
          <div className="col-md-3 mb-4">
            <img 
              src={SUIITLOGO} 
              alt="SUIIT Logo" 
              className="mb-3" 
              style={{
                maxWidth: '120px',
                backgroundColor: 'transparent',
              }} 
            />
            <ul className="list-unstyled">
              <li><h4><Link to="/" className="text-decoration-none text-dark">SUIIT</Link></h4></li>
              <li><p className="text-dark">Sambalpur University Institute of Information Technology, Jyotivihar, Burla, 768019</p></li>
              <li><p className="text-dark"><i className="bi bi-telephone-fill me-2"></i>+91 81443346426</p></li>
              <li><p className="text-dark"><i className="bi bi-globe me-2"></i>www.library.suiit.ac.in</p></li>
            </ul>
          </div>

          <div className="col-md-3 mb-4">
  <h5 className="text-dark mb-3" style={{ textAlign: 'left' }}>Quick Links</h5>
  <ul className="list-unstyled" style={{ paddingLeft: '0' }}>
    <li><Link to="https://drive.google.com/file/d/1X3b2i2CkuofXmRmXQ3Mp3_YT9IxgMjX4/view?usp=sharing" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-file-earmark-text me-2"></i> Information Brochure</Link></li>
    <li><Link to="https://suiit.ac.in/admission" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-person-check-fill me-2"></i> Admission</Link></li>
    <li><Link to="https://suiit.ac.in/placement" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-briefcase-fill me-2"></i> Placement</Link></li>
    <li><Link to="https://suiit.ac.in/institute/suiit-faculty" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-person-lines-fill me-2"></i> Faculty List</Link></li>
    <li><Link to="https://suiit.ac.in/institute/committee/anti-ragging" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-shield-lock-fill me-2"></i> Anti Ragging</Link></li>
    <li><Link to="https://suiit.ac.in/infrastructure/hostel-rules-fees" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-house-door-fill me-2"></i> Hostel</Link></li>
    <li><Link to="https://suiit.ac.in/campus-life" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-tree-fill me-2"></i> Campus Life</Link></li>
    <li><Link to="https://suiit.ac.in/institute/suiit-photos" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-image-fill me-2"></i> Photo Gallery</Link></li>
    <li><Link to="https://suiit.ac.in/rti-cell" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-file-earmark-ruled me-2"></i> RTI</Link></li>

    {/* Additional Links */}
    <li><Link to="/notices" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-file-earmark-text me-2"></i> Notices</Link></li>
    <li><Link to="/BookSoftcopy" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-book me-2"></i> E-Book</Link></li>
    <li><Link to="/PrivacyPolicy" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-file-earmark-lock me-2"></i> Privacy Policy</Link></li>
    <li><Link to="/TermsandConditions" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-file-earmark-text me-2"></i> Terms & Conditions</Link></li>
    <li><Link to="/gallery" className="text-decoration-none text-dark" style={{ display: 'flex', alignItems: 'center' }}><i className="bi bi-image me-2"></i> Gallery</Link></li>
  </ul>
</div>

          {/* Contact Information */}
          <div className="col-md-3 mb-4">
            <h5 className="text-dark mb-3">Contact Information</h5>
            <ul className="list-unstyled">
              <li><Link to="/ContactUs" className="text-decoration-none text-dark"><i className="bi bi-envelope me-2"></i> Contact Us</Link></li>
              <li><p className="text-dark">SUIIT, Jyotivihar, Burla, 768019</p></li>
              <li><p className="text-dark"><i className="bi bi-envelope-fill me-2"></i><a href="mailto:library@suiit.ac.in" className="text-dark">library@suiit.ac.in</a></p></li>
              <li><p className="text-dark"><i className="bi bi-globe me-2"></i><a href="http://www.library.suiit.ac.in" target="_blank" rel="noopener noreferrer" className="text-dark">www.library.suiit.ac.in</a></p></li>
            </ul>
          </div>

          {/* Gallery Section */}
          <div className="col-md-3 mb-4">
            <h5 className="text-dark mb-3">Gallery</h5>
            <div id="ftrglry" className="row g-2">
              {/* Add the gallery images with padding and border radius */}
              <div className="ftrimg col-4 m-2" style={{ backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit_building_image_2_197x115.jpg')", minHeight: '115px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '8px', padding: '5px' }}></div>
              <div className="ftrimg col-4 m-2" style={{ backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-8_155x115.jpg')", minHeight: '115px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '8px', padding: '5px' }}></div>
              <div className="ftrimg col-4 m-2" style={{ backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-3_153x115.jpg')", minHeight: '115px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '8px', padding: '5px' }}></div>
              <div className="ftrimg col-4 m-2" style={{ backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-4_153x115.jpg')", minHeight: '115px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '8px', padding: '5px' }}></div>
              <div className="ftrimg col-4 m-2" style={{ backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-5_153x115.jpeg')", minHeight: '115px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '8px', padding: '5px' }}></div>
              <div className="ftrimg col-4 m-2" style={{ backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-7_115x153.jpg')", minHeight: '115px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '8px', padding: '5px' }}></div>
            </div>
          </div>
        </div>

        {/* Social Media Section */}
        <div className="d-flex justify-content-center my-4">
          <a href="https://www.facebook.com/suiit.official/" target="_blank" rel="noopener noreferrer" className="mx-3">
            <i className="bi bi-facebook" style={{ fontSize: '2.2rem', color: '#3b5998' }}></i>
          </a>
          <a href="https://www.linkedin.com/school/sambalpur-university-institute-of-information-technology/?originalSubdomain=in" target="_blank" rel="noopener noreferrer" className="mx-3">
            <i className="bi bi-linkedin" style={{ fontSize: '2.2rem', color: '#0a66c2' }}></i>
          </a>
          <a href="https://twitter.com/suiit_official" target="_blank" rel="noopener noreferrer" className="mx-3">
            <i className="bi bi-twitter" style={{ fontSize: '2.2rem', color: '#1da1f2' }}></i>
          </a>
          <a href="https://www.instagram.com/suiit.official/?hl=en" target="_blank" rel="noopener noreferrer" className="mx-3">
            <i className="bi bi-instagram" style={{ fontSize: '2.2rem', color: '#e1306c' }}></i>
          </a>
        </div>

        {/* Footer Text */}
        <div className="text-center">
          <p className="text-dark">© {new Date().getFullYear()} SUIIT Library Management System. All rights reserved.</p>
          <p className="text-dark">Visit our official website: <a href="http://www.suiit.ac.in" target="_blank" rel="noopener noreferrer" className="text-dark">www.suiit.ac.in</a></p>
        </div>
      </div>

        </footer>
        <div style={{ backgroundImage: `url(${Sambalpuri})`, backgroundRepeat: 'repeat-x', backgroundSize: 'contain', height: '35px', width: '100%', backgroundPosition: 'center' }}></div>
<div style={{ backgroundImage: `url(${Sambalpuri1})`, backgroundRepeat: 'repeat-x', backgroundSize: 'contain', height: '40px', width: '100%', backgroundPosition: 'center' }}></div>
</div>
  );
};

export default Footer;


