import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import styles from "./css/Navbar.module.css";
import  './css/Navbar.css';
import SUIITLOGO from './images/SUIITLOGO.png';
import userIcon from './images/user.png';
import { auth } from '../firebaseConfig';
import {signOut} from 'firebase/auth';


const errorMessages = {
  'auth/invalid-email': 'Please enter a valid email address.',
  'auth/user-disabled': 'This user has been disabled. Please contact support.',
  'auth/user-not-found': 'No account found with this email. Please sign up.',
  'auth/wrong-password': 'Incorrect password. Please try again.',
  'auth/email-already-in-use': 'An account with this email already exists. Please log in.',
  'auth/operation-not-allowed': 'Email/password accounts are not enabled. Please contact support.',
  'auth/weak-password': 'Password should be at least 6 characters.',
  'auth/too-many-requests': 'Too many requests. Please try again later.',
  'auth/network-request-failed': 'Network error. Please check your internet connection.',
  'default': 'An unexpected error occurred. Please try again.'
};

const getFriendlyErrorMessage = (error) => {
  return errorMessages[error.code] || errorMessages['default'];
};

const CustomNavbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef(null);
  const location = useLocation(); // To determine the active page
  const [error, setError] = useState('');
  const handleToggle = () => setIsExpanded(!isExpanded);
  const handleLinkClick = () => setIsExpanded(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleButtonRef = useRef(null); // Create a ref for the toggle button
  const lightIconRef = useRef(null);
  const darkIconRef = useRef(null);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  
  const isAdmin = user && (user.email === "22btcse06@suiit.ac.in" || user.email === "library@suiit.ac.in" || user.email === "skpurohit@suiit.ac.in") && user.emailVerified;
  const isFaculty = user && user.email.endsWith('@suiit.ac.in') && !/\d/.test(user.email) && user.emailVerified;
  const isStudent = user && user.email.endsWith('@suiit.ac.in') && /\d/.test(user.email) && user.emailVerified;

  const toggleTheme = () => {
    setIsDarkMode(prevState => !prevState);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
         setUser(auth.currentUser)
    }
  }, []);

  useEffect(() => {
    // When the component mounts, check if dark mode is active in localStorage
    
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "inverted") {
      setIsDarkMode(true);
    }
  }, []);

  useEffect(() => {
    // Apply or remove dark mode
    const rootElement = document.documentElement;
    

    if (isDarkMode) {
      rootElement.classList.add("inverted");
      lightIconRef.current.style.display = "none";
      darkIconRef.current.style.display = "inline";
      localStorage.setItem("theme", "inverted");
    } else {
      rootElement.classList.remove("inverted");
      lightIconRef.current.style.display = "inline";
      darkIconRef.current.style.display = "none";
      localStorage.setItem("theme", "normal");
    }
  }, [isDarkMode]);

  // Close the navbar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Navbar shadow on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        navbarRef.current.classList.add(styles.navbarShadow);
      } else {
        navbarRef.current.classList.remove(styles.navbarShadow);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setError('');
    } catch (err) {
      const friendlyMessage = getFriendlyErrorMessage(err);
      setError(friendlyMessage);
      setShowModal(true);
    }
  };
  // Styling for the active link
  const getLinkStyle = (path) => {
    const baseStyle = {
      color: "white",
      padding: "12px 20px",
      display: "flex",
      alignItems: "center",
      transition: "transform 0.3s ease",
      borderRadius: "5px",
      backgroundColor: "transparent", // Always transparent
    };
    const activeStyle = {
      fontWeight: "bold",
      color: "#00c896", // Active link color
    };
    return location.pathname === path ? { ...baseStyle, ...activeStyle } : baseStyle;
  };

  const hoverStyle = {
    transform: "scale(1.1)",
    color: "#a4d65e",
  };

  return (
    <Navbar
      className={`${styles.customNavbar}`}
      expand="lg"
      fixed="top"
      ref={navbarRef}
      expanded={isExpanded}
      style={{boxShadow:'none' ,marginTop:'-10px'}}
      
      >
    
      <div className="container-fluid mr-0 pr-0"  style={{paddingtop:'-32px',paddingRight:'-100px' }} >
        <Navbar.Brand as={Link} to="/" className={styles.navbarBrand}>
        <div className="d-flex align-items-center" id="head">
  <img
    src={SUIITLOGO}
    alt="Library Logo"
    style={{ width: '30px', height: 'auto' }}
    id="head"  // Adjust the size of the logo
  />
  <span
    style={{ fontSize: '1rem', color: 'white', marginLeft: '8px' }}
    id="head"  // Adjust the text size and spacing
  >
    SUIIT Library
  </span>
</div>
 
          <span className={styles.brandText} style={{ fontSize: '1.3rem', color: 'white', }} id='pc'>SUIIT Library</span> 
        </Navbar.Brand>
        <div
        ref={toggleButtonRef} // Attach the ref to the button
        onClick={toggleTheme}
        className="btn btn-light"
        style={{backgroundColor:'transparent' ,border:'none'}}
      >
        <i ref={lightIconRef} className="bi bi-sun" style={{ color:"yellow" }}></i>
        <i ref={darkIconRef} className="bi bi-moon" style={{ display: 'none' ,color:"white" }}></i>
      </div>
      <Navbar.Toggle 
  onClick={handleToggle} 
  aria-controls="basic-navbar-nav"
  style={{ border: 'none', background: 'transparent',boxShadow:'none' }} // Optional: make the button background transparent
>


{user ? <NavDropdown
  title={<span><img src={user.photoURL || userIcon} alt={user.email} className="rounded-circle me-2" style={{ height: '20px', width: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} />Account</span>}
  id="login-dropdown"
  className={styles.navDropdown}
>
  { isStudent ? <div><NavDropdown.Item as={Link} to="/student" className="Dropdown">
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isFaculty ? <div> <NavDropdown.Item as={Link} to="/faculty" className="Dropdown">
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isAdmin ? <div>  <NavDropdown.Item as={Link} to="/admin" className="Dropdown">
    <i className="bi bi-shield-lock me-2"></i> Admin
  </NavDropdown.Item>
  </div> : null}

  <NavDropdown.Item
        onClick={handleSignOut}
        className="Dropdown">
      
      <i className="bi bi-box-arrow-right me-2"></i>Log out

      </NavDropdown.Item>
</NavDropdown> : 
(
<NavDropdown
  title={<span><i className="bi bi-person-circle me-2"></i> Login</span>}
  id="login-dropdown"
  className={styles.navDropdown}
>
  <NavDropdown.Item as={Link} to="/student" className="Dropdown">
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/faculty" className="Dropdown">
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/admin" className="Dropdown">
    <i className="bi bi-shield-lock me-2"></i> Admin
  </NavDropdown.Item>
</NavDropdown>
)}




  {/* <i className="material-icons" style={{ color: 'white', fontSize: '2rem' }}>
    menu
  </i> */}
</Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`me-auto ${styles.navbarLinks}`} id="hide">
          { !user && !isAdmin ?   <Nav.Link
              as={Link}
              to="/"
              onClick={handleLinkClick}
              style={getLinkStyle("/")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/"))}
            >
              <i className="material-icons me-2">home</i> Home
            </Nav.Link>
            :null}
            { user && isAdmin ? 
            <Nav.Link
              as={Link}
              to="/admin"
              onClick={handleLinkClick}
              style={getLinkStyle("/admin")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/admin"))}
            >
              <i className="material-icons me-2">admin_panel_settings</i> Admin Panel
            </Nav.Link> :null}
            
            { user && isFaculty && !isAdmin? 
            <Nav.Link
              as={Link}
              to="/faculty"
              onClick={handleLinkClick}
              style={getLinkStyle("/faculty")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/faculty"))}
            >
              <i className="material-icons me-2">admin_panel_settings</i> Faculty Panel
            </Nav.Link> :null}
            { user && isStudent && !isAdmin ? 
            <Nav.Link
              as={Link}
              to="/student"
              onClick={handleLinkClick}
              style={getLinkStyle("/student")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/student"))}
            >
              <i className="material-icons me-2">admin_panel_settings</i> Student Panel
            </Nav.Link> :null}
            {/* <Nav.Link
              as={Link}
              to="/notices"
              onClick={handleLinkClick}
              style={getLinkStyle("/notices")}
              onMouseOver={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
              onMouseOut={(e) => Object.assign(e.currentTarget.style, getLinkStyle("/notices"))}
            >
              <i className="material-icons me-2">notifications</i> Notices
            </Nav.Link> */}
            
          </Nav>

          
{/* View Books Dropdown */}
{/* <NavDropdown
  title={<span><i className="bi bi-book-half me-2"></i>Books</span>}
  id="view-books-dropdown"
  className={styles.navDropdown}
>
  <NavDropdown.Item as={Link} to="/books" className="Dropdown">
    <i className="bi bi-journal-text me-2"></i> Library Books
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/BookSoftcopy" className="Dropdown">
    <i className="bi bi-file-earmark-pdf me-2"></i> E-Books
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  <NavDropdown.Item as={Link} to="/required-books" className="Dropdown">
    <i className="bi bi-journal-plus me-2"></i> Request Book
  </NavDropdown.Item>
 
</NavDropdown> */}

{/* About Dropdown */}
{/* <NavDropdown
  title={<span><i className="bi bi-info-circle me-2"></i> About</span>}
  id="about-dropdown"
  className={styles.navDropdown}
>
  <NavDropdown.Item as={Link} to="/contact" className="Dropdown">
    <i className="bi bi-envelope me-2"></i> Contact
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/gallery" className="Dropdown">
    <i className="bi bi-images me-2"></i> Gallery
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

 

  <NavDropdown.Item as={Link} to="/payment" className="Dropdown">
    <i className="bi bi-currency-exchange me-2"></i> Payment
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/notices" className="Dropdown">
    <i className="bi bi-bell me-2"></i> Notices
  </NavDropdown.Item>
</NavDropdown> */}

{/* Login Dropdown */}
<div id='hide'>
{user ? <NavDropdown
  title={<span><img src={user.photoURL || userIcon} alt={user.email} className="rounded-circle me-2" style={{ height: '20px', width: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} />Account</span>}
  id="login-dropdown"
  className={styles.navDropdown}
>
  { isStudent ? <div><NavDropdown.Item as={Link} to="/student" className="Dropdown">
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isFaculty ? <div> <NavDropdown.Item as={Link} to="/faculty" className="Dropdown">
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>
  </div>
  :null}

  { isAdmin ? <div>  <NavDropdown.Item as={Link} to="/admin" className="Dropdown">
    <i className="bi bi-shield-lock me-2"></i> Admin
  </NavDropdown.Item>
  </div> : null}

  <NavDropdown.Item
        onClick={handleSignOut}
        className="Dropdown">
      
      <i className="bi bi-box-arrow-right me-2"></i>Log out

      </NavDropdown.Item>
</NavDropdown> : 
(
<NavDropdown
  title={<span><i className="bi bi-person-circle me-2"></i> Login</span>}
  id="login-dropdown"
  className={styles.navDropdown}
>
  <NavDropdown.Item as={Link} to="/student" className="Dropdown">
    <i className="bi bi-person-badge me-2"></i> Student
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/faculty" className="Dropdown">
    <i className="bi bi-person-workspace me-2"></i> Faculty
  </NavDropdown.Item>
  <div className="dropdown-divider"></div>

  <NavDropdown.Item as={Link} to="/admin" className="Dropdown">
    <i className="bi bi-shield-lock me-2"></i> Admin
  </NavDropdown.Item>
</NavDropdown>
)}
</div>
{showModal && (
    <div className={`modal ${styles.errorModal}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className={`modal-content ${styles.modalContent}`}>
          <div className="modal-header">
            <h5 className="modal-title">Error</h5>
            <button type="button" className="close" onClick={() => setShowModal(false)}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{error}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )}
        </Navbar.Collapse>
      </div>
    </Navbar>
    
  );
};

export default CustomNavbar;
